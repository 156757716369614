import { Box, MenuItem, Select, SelectChangeEvent, InputLabel, FormControl } from "@mui/material"
import { allServers } from "../server";

interface ServerControlProps {
    serverURL: string
    onChange: (serverURL: string) => void;

}

export default function ServerControl(props: ServerControlProps) {
    const handleChange = (event: SelectChangeEvent) => {
        const id = event.target.value as string
        const url = allServers.find((s) => s.id === id)?.url || ""
        console.log("new url:", url)
        props.onChange(url)
    };

    const selected = allServers.find(v => v.url === props.serverURL)?.id || "no server"

    return (
        <Box sx={{ backgroundColor: '#fff' }}>
            <FormControl>
                <InputLabel id="env">Env</InputLabel>
                <Select
                    value={selected}
                    onChange={handleChange}
                    label="Env"
                >
                    {
                        allServers.map(spec =>
                            <MenuItem key={spec.id} value={spec.id}>{spec.displayName}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </Box>
    )
}
