import { useEffect, useState } from 'react';
import { FormControl, Box, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"

import { Available, getAllAvailable } from "../forecast";

export interface ForecastDatasetControlProps {
    serverURL: string;
    onDatasetSelected: (a: Available | null) => void;
    selectedDataset: string;
}

export default function ForecastDatasetControl(props: ForecastDatasetControlProps) {
    const [allAvailable, setAllAvailable] = useState<Available[]>([]);

    useEffect(() => {
        getAllAvailable(props.serverURL)
            .then(a => setAllAvailable(a))
    }, [props.serverURL])

    const handleDatasetChange = (event: SelectChangeEvent<string>) => {
        const selectedDataset = allAvailable.find(available => available.name === event.target.value) || null;
        props.onDatasetSelected(selectedDataset);
    };

    return (
        <Box sx={{ paddingLeft: 5, paddingRight: 5 }}>
            <FormControl>
                <InputLabel id="forecast-Datasets">Datasets</InputLabel>
                <Select sx={{ minWidth: 150 }} onChange={handleDatasetChange}
                    value={props.selectedDataset} label="Datasets">
                    {allAvailable && allAvailable.map(Dataset => (
                        <MenuItem key={Dataset.name} value={Dataset.name}>{Dataset.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}