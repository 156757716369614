import React, { useState } from 'react';
import Map, { NavigationControl, Source, Layer, AttributionControl } from '@vis.gl/react-maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import { allServers } from "./server";
import { Available } from './forecast';

import ForecastDatasetControl from './components/ForecastDatasetControl';
import TimeControl from './components/TimeControl';
import ServerControl from './components/ServerControl';

import { Box } from '@mui/material';

const MAP_STYLE = 'https://demotiles.maplibre.org/style.json';

const ForecastMap: React.FC = () => {
    const [currentDataset, setCurrentDataset] = useState<Available | null>(null);
    const [timeStep, setTimeStep] = useState<number>(0);
    const [serverURL, setServerURL] = useState<string>(allServers[0].url)

    function changeDataset(a: Available | null) {
        if (!a) {
            return
        }

        // Keep current timestep if number of timesteps available 
        // is the same in old and new Dataset
        if (!currentDataset ||
            a.times.length !== currentDataset.times.length) {
            setTimeStep(0)
        }
        setCurrentDataset(a)
    }

    return (
        <Box sx={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', paddingTop: 2, paddingBottom: 1 }}>
                <ForecastDatasetControl serverURL={serverURL} onDatasetSelected={changeDataset} selectedDataset={currentDataset ? currentDataset.name : ""} />
                <ServerControl serverURL={serverURL} onChange={setServerURL} />
            </Box>
            <Map
                initialViewState={{
                    longitude: 10,
                    latitude: 60,
                    zoom: 4
                }}
                mapStyle={MAP_STYLE}
                maxZoom={6}
                attributionControl={false}

            >
                {currentDataset &&
                    <Source
                        key={currentDataset.name + timeStep.toString()}
                        id={currentDataset.name + timeStep.toString()}
                        type="raster"
                        tiles={[currentDataset.times[timeStep].tiles.webp]}
                        tileSize={256}
                        scheme="xyz"
                    >
                        <Layer
                            id={currentDataset.name + timeStep.toString()}
                            type="raster"
                            source={currentDataset.name}
                            paint={{ "raster-opacity": 0.6 }}
                        />
                    </Source>
                }
                <AttributionControl customAttribution="Maplibre | Maps by Maplibre. Forecast data by MET Norway." position="bottom-right" />
                <NavigationControl position="top-left" />
            </Map>
            {currentDataset &&
                <TimeControl
                    currentTime={timeStep}
                    timeSteps={currentDataset?.times.map(step => step.time) || []}
                    onChange={setTimeStep}
                />
            }
        </Box>
    );
};

export default ForecastMap;
