import { z } from "zod";

const available = z.object({
    name: z.string(),
    bounds: z.array(z.number()),
    minzoom: z.number().int(),
    maxzoom: z.number().int(),
    times: z.array(z.object({
        time: z.string().datetime(),
        tiles: z.object({
            webp: z.string()
        })
    }))
});

const datasetsList = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        url: z.string(),
    })
)

// extract the inferred type
export type Available = z.infer<typeof available>;

export async function getAvailable(metadataURL: string): Promise<Available> {
    const response = await fetch(metadataURL);
    const ret = available.parse(await response.json());
    return ret;
}

export async function getAllAvailable(serverURL: string): Promise<Available[]> {
    const datasetsURL = serverURL + "/api/available.json";

    const response = await fetch(datasetsURL);
    const datasets = datasetsList.parse(await response.json())

    const jobs: Promise<Available>[] = []
    for (const dataset of datasets)
        jobs.push(getAvailable(dataset.url))

    const ret: Available[] = []
    for (const job of jobs)
        ret.push(await job)

    return ret;
}
